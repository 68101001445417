import { useForm, FieldValues } from "react-hook-form";
import TextField from "@mui/material/TextField";
import LoadingButton from "@mui/lab/LoadingButton";
import Box from "@mui/material/Box";
import Typography from "@mui/material/Typography";
import { useNavigate } from "react-router-dom";
import { useAppDispatch } from "../../app/store/configureStore";
import { addSite } from "../catalog/catalogSlice";
import Layout from "../../app/layout/Layout";
import { Card, CardContent, CardHeader, Divider, Grid, Icon } from "@mui/material";
import { EuiStepsHorizontal, EuiStepsHorizontalProps } from "@elastic/eui";
import StarPurple500Icon from '@mui/icons-material/StarPurple500';
import CheckCircleOutlineIcon from '@mui/icons-material/CheckCircleOutline';
import { useAuth, useOrganization, useUser } from "@clerk/clerk-react";
import { useFlow } from "@frigade/react";
import { useEffect } from "react";

export default function AddSite() {
  const navigate = useNavigate();
  const dispatch = useAppDispatch();

  const {
    register,
    handleSubmit,
    formState: { isSubmitting, errors, isValid },
  } = useForm({
    mode: "all",
  });

  const { isSignedIn } = useAuth();
  const { user } = useUser();
  const {flow} = useFlow("flow_6fi97SYs");

  useEffect(() => {
    if (isSignedIn) {

      if (flow && user?.organizationMemberships && user.organizationMemberships.length > 0) {
         flow.steps.get('step-1')?.complete();
      }

    }
  }, []);


  async function submitForm(data: FieldValues) {
    try {
      const response = await dispatch(addSite(data));
  
      // Check if the response indicates success 
      if (response && response.meta.requestStatus == "fulfilled") {
        navigate("/catalog");
      } else {
        console.log("Failed to add site");
      }
    } catch (error) {
      console.log("Error adding new site");
    }
  }

  const horizontalSteps: EuiStepsHorizontalProps['steps'] = [
    {
      title: 'Organization creation',
      status: 'complete',
      onClick: () => {},
    },
    {
      title: 'Add your web application',
      status: 'current',
      onClick: () => {},
    },
    {
      title: 'Add project details',
      onClick: () => {},
    },
    {
      title: 'Setup completed',
      status: 'disabled',
      onClick: () => {},
    },
  ];

  return (
    <Layout>

      <EuiStepsHorizontal steps={horizontalSteps} />

      <Box
        sx={{
          marginTop: 8,
          display: "flex",
          flexDirection: "column",
          alignItems: "center",
          justifyContent: "center",
          width: "60 %"
        }}
      >
        <Typography component="h1" variant="h5" textAlign="center" mb={3}>
          Start using HackGATE™
        </Typography>
        <Typography textAlign="center" variant="h6" mb={3}>
        To get started with HackGATE, enter the URL of the web application you want to monitor.
        </Typography>
        <Typography textAlign="center" mb={1}>
        This URL is crucial for creating a HackGATE environment for your application.
        </Typography>
        <Typography textAlign="center" mb={3}>
        By providing this information, we can set up HackGATE to control and monitor your pentest project.
        </Typography>
    
        
        
        <Box
          component="form"
          onSubmit={handleSubmit(submitForm)}
          noValidate
          sx={{ mt: 1 }}
          alignItems= "center"
          alignContent= "center"
          justifyContent= "center"
        >

          <TextField
            margin="normal"
            required
            fullWidth
            id="originName"
            label="Enter your site (https://test.mycompany.com)"
            autoComplete="originName"
            autoFocus
            {...register("originName", { required: "Site is required" })}
            error={!!errors.originName}
            helperText={errors?.originName?.message?.toString()}
            InputProps={{
              sx: {borderRadius: "8px"}
            }}
          />
    <Typography textAlign="center" mb={3}>
        Use the FQDN format, such as https://app.mycompany.com.
        </Typography>

          <Typography mt={5} textAlign="center">
          You'll be enrolled in our Free Plan, offering essential features at no cost.
          </Typography>
          <Typography mb={5} textAlign="center">
          You can upgrade your plan at any time.
          </Typography>

          {/* <Card variant="outlined" sx={{borderRadius: "12px", borderWidth: "2px", width: "75%"}}>
            <CardContent>
              <Grid container>
              <Grid item xs={2}>
                <Icon fontSize="large">
                  <StarPurple500Icon fontSize="large"/>
                </Icon>
                </Grid>
                <Grid item xs={10}>
                <Typography variant="h6" mb={1}>
              <Box sx={{ fontWeight: 'bold' }}> HackGATE™ Beta Plan
              </Box>
              </Typography>
              <Divider/>
              <Typography variant="h4" mt={2} mb={2}>Free</Typography>
              <Typography> This plan includes...</Typography>
              <Typography> <Icon><CheckCircleOutlineIcon fontSize="small"/></Icon>Access to all basic features</Typography>
              <Typography> <Icon><CheckCircleOutlineIcon fontSize="small"/></Icon>Basic reporting and analytics</Typography>
              <Typography> <Icon><CheckCircleOutlineIcon fontSize="small"/></Icon>Chat and email support</Typography>
                </Grid>
              </Grid>
            
            </CardContent>
          </Card> */}

          <LoadingButton
            loading={isSubmitting}
            disabled={!isValid}
            type="submit"
            fullWidth
            variant="outlined"
            sx={{ mt: 3, mb: 2, borderRadius: "8px" }}
          >
            Add Site
          </LoadingButton>
        </Box>
      </Box>
    </Layout>
  );
}
