import { useAuth, useOrganization, useUser } from "@clerk/clerk-react";
import { useFlow } from "@frigade/react";
import { Typography } from "@mui/material";
import { useEffect, useState } from "react";
import Layout from "../../app/layout/Layout";
import * as Frigade from '@frigade/react';
import { Site } from "../../app/models/site";
import LoadingComponent from "../../app/layout/LoadingComponent";
import agent from "../../app/api/agent";
import Login from "../account/Login";
import { Link, useNavigate } from "react-router-dom";

export default function HomePage() {

  const [sites, setSites] = useState<Site[]>([]);
  const [loading, setLoading] = useState(true);
  const navigate = useNavigate();
  
    const { isSignedIn } = useAuth();
    const { user } = useUser();

    const { organization } = useOrganization();
  
    const {flow} = useFlow("flow_6fi97SYs");
  
    useEffect(() => {
      if (isSignedIn) {
  
        if (flow && user?.organizationMemberships && user.organizationMemberships.length > 0) {
           flow.steps.get('step-1')?.complete();
        }

        agent.Catalog.list()
        .then((sites) => {setSites(sites)

        if (sites && sites.length > 0) {
          flow?.steps.get('step-2')?.complete();
        }
        })
        .catch((error) => console.log(error))
        .finally(() => setLoading(false));

        if (organization &&  organization.membersCount > 1) {
          flow?.steps.get('step-4')?.complete();
        }

      }
    }, [organization, flow]);
  
    if (!isSignedIn) {
      // Redirect to login page if user is not authenticated
      navigate("/login");
      return <Login />;
    }

    
  if (isSignedIn && loading)
    return <LoadingComponent message="Contacting with the mothership..." />;

    return (
        <Layout>
            <Typography variant="h4" mb={3}>
            Welcome, {user?.firstName}! 👋
            </Typography>

            <Frigade.Checklist.Collapsible
             flowId="flow_6fi97SYs"
             forceMount
             variables={{
              siteId: sites[0]?.id
             }}
              />
        </Layout>
    )
}