import Typography from "@mui/material/Typography";
import { ThemeProvider } from "@emotion/react";
import { Box, Container, Grid, Link, Paper, createTheme } from "@mui/material";
import { Image } from "mui-image";
import { SignUp } from "@clerk/clerk-react";
import { useEffect } from "react";
import ReactGA from 'react-ga4';

export default function Login() {
  const theme = createTheme({
    typography: {
      button: {
        textTransform: "none",
      },
    },
  });

  useEffect(() => {
    ReactGA.send({ hitType: "pageview", page: window.location.pathname });
  }, []);


  return (
    <ThemeProvider theme={theme}>
      <Grid container component="main" sx={{ height: "100vh", padding: "16px"}}>
      <Grid item xs={12} sm={5} md={5} component={Paper} elevation={0} square sx={{paddingX: 3, paddingY: 1}}>      <Typography
              component="h1"
              variant="h4"
              sx={{ fontWeight: 600 }}
            >
              HackGATE™
            </Typography>
          <Box
            sx={{
              py: 5,
              mx: 4,
              display: "flex",
              flexDirection: "column",
              alignItems: "center",
            }}
          >
            <Container component="main" maxWidth="xs">
               <Typography
                component="h1"
                variant="h5"
                mb={1}
                sx={{ fontWeight: 600, textAlign: "left" }}
              >
                Lets get started with your free trial!
              </Typography>
              <Typography variant="h6" mb={5}>
                You're 3 short steps from monitor your ethical hacking project 🚀
              </Typography> 

              <SignUp redirectUrl="/onboarding/checkUser" signInUrl="/login" />


              <Typography variant="body2" mt={4} textAlign="center">
              By signing up, I agree to HackGATE's 
              {" "}
              <Link
              color="inherit"
              href="https://www.hackgate.io/terms-conditions"
              target="_blank"
              underline="hover"
              sx={{ fontWeight: "bold" }}
            >
              Terms of Service
            </Link> 
            {" "}and
            {" "} 
            <Link
              color="inherit"
              href="https://www.hackgate.io/privacy-notice"
              target="_blank"
              underline="hover"
              sx={{ fontWeight: "bold" }}
            >
             Privacy Policy
            </Link>.
              </Typography>
             
            </Container>
          </Box>
        </Grid>
        <Grid
          item
          xs={false}
          sm={7}
          md={7}
          sx={{
            borderRadius: "12px",
            marginBottom: "12px",
            background: 'linear-gradient(45deg, #7915D6 30%, #754FC6 90%)', // Gradient background
          }}
        >
          <Box
            sx={{
              py: 10,
              mx: 10,
              display: "flex",
              flexDirection: "column",
              alignItems: "center",
            }}
          >

          <Image width={"66%"} src="/img/login_illustration_2.png" />

            <Typography
              component="h1"
              variant="h4"
              mb={2}
              mt={3}
              sx={{ fontWeight: 600, textAlign: "center", color: "white" }}
            >
              Enhanced visibility
            </Typography>
            <Typography variant="body1" color="white" mb={4} textAlign="center">
              HackGATE is purpose-built to supervise hacking activities and
              promote accountability among pentesters. By providing improved
              visibility, you can closely monitor their actions and ensure
              adherence to ethical guidelines.
            </Typography>

            <Grid container justifyContent="center" alignItems="center">
              <Grid item>
                <Image width={75} src="https://images.g2crowd.com/uploads/report_medal/image/1004327/medal.svg" />
              </Grid>
              <Grid item>
                <Image width={75} src="https://images.g2crowd.com/uploads/report_medal/image/1004387/medal.svg" />
              </Grid>
              <Grid item>
                <Image width={75} src="https://images.g2crowd.com/uploads/report_medal/image/1004379/medal.svg" />
              </Grid>
            </Grid>
          </Box>
          <Typography variant="body2" color="white" align="center">
            {"Copyright © "}
            {new Date().getFullYear() + " "}
            <Link
              color="inherit"
              href="https://hckrt.com/"
              target="_blank"
              underline="hover"
              sx={{ fontWeight: "bold" }}
            >
              Hackrate
            </Link>{" "}
          </Typography>
        </Grid>

      </Grid>
    </ThemeProvider>
  );
}
