import {
  Container,
  createTheme,
  CssBaseline,
  ThemeProvider,
} from "@mui/material";
import { Fragment } from "react";
import { Routes, Route } from "react-router-dom";
import { ToastContainer } from "react-toastify";
import Login from "../../features/account/Login";
import Register from "../../features/account/Register";
import Catalog from "../../features/catalog/Catalog";
import SiteDetails from "../../features/catalog/SiteDetails";
import ServerError from "../errors/ServerError";
import Profile from "../../features/account/Profile";
import AddSite from "../../features/catalog/AddSite";
import Members from "../../features/manage/Members";
import Billing from "../../features/billing/Billing";
import Analytics from "../../features/analytics/Analytics";
import Hackers from "../../features/catalog/Hackers";
import Start from "../../features/catalog/Start";
import Stop from "../../features/catalog/Stop";
import Instructions from "../../features/catalog/Instructions";
import Credentials from "../../features/catalog/Credentials";
import BillingUpdate from "../../features/billing/Update";
import LogView from "../../features/catalog/Log";
import CheckUser from "../../features/onboarding/checkUser";
import CreateTenant from "../../features/onboarding/createTenant";
import Terms from "../../features/others/Terms";
import Privacy from "../../features/others/Privacy";
import ReactGA from "react-ga4";
import { ClerkProvider, useUser } from "@clerk/clerk-react";
import { EuiProvider } from "@elastic/eui";
import * as Frigade from "@frigade/react";

import "@elastic/eui/dist/eui_theme_light.css";
import "react-toastify/dist/ReactToastify.css";
import HomePage from "../../features/home/HomePage";

function App() {
  const theme = createTheme({
    palette: {
      mode: "light",
      background: {},
    },
  });

  const { user } = useUser();

  document.body.style.backgroundColor = "#fff";

  ReactGA.initialize("G-99MGTMEJ16");

  if (!process.env.REACT_APP_FRIGADE_PUBLISHABLE_KEY) {
    throw new Error("Missing Publishable Key");
  }

  const frigadePubKey = process.env.REACT_APP_FRIGADE_PUBLISHABLE_KEY;

  // if (loading)
  //   return <LoadingComponentFullPage message="Contacting with the mothership..." />;

  return (
    <Fragment>
        <Frigade.Provider
          apiKey={frigadePubKey}
          userId={user?.id}
          userProperties={{
            firstName : user?.firstName,
            lastName: user?.lastName,
            email: user?.primaryEmailAddress?.emailAddress
          }}
          theme={{
            colors:{
              primary:{
                surface: "#7f56d9",
                border: "#7f56d9",
                hover:{
                  surface: "#6941c6",
                  border: "#6941c6"
                }
              }
            }
          }}
        >
          <ThemeProvider theme={theme}>
            <EuiProvider>
              <ToastContainer position="top-right" closeButton theme="light" />
              <CssBaseline />
              <Routes>
                <Route path="/login" element={<Login />} />
                <Route path="/register" element={<Register />} />
                <Route path="/server-error" element={<ServerError />} />
              </Routes>

              <Container maxWidth="xl" sx={{ backgroundColor: "#fff" }}>
                <Routes>
                  <Route path="/home" element={<HomePage />} />
                  <Route path="/catalog" element={<Catalog />} />
                  <Route path="/catalog/add-site" element={<AddSite />} />
                  <Route path="/catalog/:id" element={<SiteDetails />} />
                  <Route path="/catalog/:id/hackers" element={<Hackers />} />
                  <Route path="/catalog/:id/log" element={<LogView />} />
                  <Route path="/catalog/:id/start" element={<Start />} />
                  <Route path="/catalog/:id/stop" element={<Stop />} />
                  <Route
                    path="/catalog/:id/instructions"
                    element={<Instructions />}
                  />
                  <Route
                    path="/catalog/:id/credentials"
                    element={<Credentials />}
                  />
                  <Route path="/manage" element={<Members />} />
                  <Route path="/billing" element={<Billing />} />
                  <Route path="/onboarding/checkuser" element={<CheckUser />} />
                  <Route
                    path="/onboarding/checkuser/:state"
                    element={<CheckUser />}
                  />
                  <Route
                    path="/onboarding/createtenant"
                    element={<CreateTenant />}
                  />
                  <Route path="/billing/update" element={<BillingUpdate />} />
                  <Route path="/analytics" element={<Analytics />} />
                  <Route path="/profile" element={<Profile />} />
                  <Route path="/terms" element={<Terms />} />
                  <Route path="/privacy" element={<Privacy />} />
                  <Route path="/" element={<HomePage />} />
                </Routes>
              </Container>
            </EuiProvider>
          </ThemeProvider>
        </Frigade.Provider>
    </Fragment>
  );
}

export default App;
