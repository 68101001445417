import Box from "@mui/material/Box";
import Typography from "@mui/material/Typography";
import { Link, useNavigate } from "react-router-dom";
import { useEffect } from "react";
import agent from "../../app/api/agent";
import { toast } from "react-toastify";
import { Button, CircularProgress } from "@mui/material";

import { useAuth, useUser } from "@clerk/clerk-react";

export default function CheckUser() {
  const navigate = useNavigate();
  const { isSignedIn, getToken, isLoaded } = useAuth();
  const { user } = useUser();

  useEffect(() => {
    if (isSignedIn) {
      console.log("Signed in");

      const getJwt = async () => {
        try {
          const token = await getToken();

          agent.Account.currentUser();

          // fetch your data
          //console.log(token);
          //console.log(user?.organizationMemberships);

          if (user?.organizationMemberships.length === 0) {
            navigate("/onboarding/CreateTenant");
            toast.success("Hello friend!");
          } else {
            navigate("/home");
            toast.success("Welcome back!");
          }
        } catch (error) {
          console.log(error);
        }
      };

      getJwt();
    }
  }, [isLoaded]);

  return (
    <Box
      sx={{
        height: 400,
        width: "100%",
        display: "flex",
        flexDirection: "column",
        alignItems: "center",
        justifyContent: "center",
      }}
    >
      <CircularProgress size={40} />

      <Typography textAlign={"center"} sx={{ mt: 5, mb: 3 }}>
        Redirecting to onboarding page...
      </Typography>

      <Button variant="outlined" component={Link} to="/catalog">
        Go back to the home page
      </Button>
    </Box>
  );
}
