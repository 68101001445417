import {
  Box,
  Grid,
  TextField,
  Typography,
} from "@mui/material";
import { useEffect, useState } from "react";
import { useParams } from "react-router";
import LoadingComponent from "../../app/layout/LoadingComponent";
import { fetchSiteAsync, siteSelectors, startSite } from "./catalogSlice";
import { useAppDispatch, useAppSelector } from "../../app/store/configureStore";
import Layout from "../../app/layout/Layout";
import { useNavigate } from "react-router-dom";
import {
  DesktopDateTimePicker,
  LocalizationProvider,
} from "@mui/x-date-pickers";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import BackButton from "../../app/layout/BackButton";
import { useForm, Controller } from "react-hook-form";
import "dayjs/locale/en-gb";
import { LoadingButton } from "@mui/lab";
import { EuiBreadcrumb, EuiBreadcrumbs, EuiSpacer } from "@elastic/eui";
import { useFlow } from "@frigade/react";

export default function Start() {
  const dispatch = useAppDispatch();
  const { id } = useParams<{ id: string }>();
  const site = useAppSelector((state) => siteSelectors.selectById(state, id!));
  const { status: siteStatus } = useAppSelector((state) => state.catalog);

  const [submiting, setSubmiting] = useState(false);

  const navigate = useNavigate();

  useEffect(() => {
    if (!site) dispatch(fetchSiteAsync(id!));
  }, [id, site, dispatch]);

  const {
    control,
    handleSubmit,
    register,
    formState: { isSubmitting, errors, isValid },
  } = useForm({
    mode: "all",
  });

  const {flow} = useFlow("flow_6fi97SYs");

  const submitForm = async (data: any) => {
    try {
      setSubmiting(true);
      await dispatch(startSite(data));
      flow?.steps.get('step-3')?.complete();
      setSubmiting(false);
    } catch (error) {
      console.log("Error adding new site");
    }
  };

  const breadcrumbs: EuiBreadcrumb[] = [
    {
      text: 'Websites',
      href: 'catalog/',
      onClick: (e) => {
        e.preventDefault();
      },
      'data-test-subj': 'breadcrumbsAnimals',
    },
    {
      text: site?.originName,
      href: '/',
      onClick: (e) => {
        e.preventDefault();
      },
    },
    {
      text: 'Start Schedule',
      href: '#',
      onClick: (e) => {
        e.preventDefault();
      },
    },
  ];

  useEffect(() => {
    dispatch(fetchSiteAsync(id!));
  }, [isSubmitting]);

  if (siteStatus.includes("pending"))
    return <LoadingComponent message="Loading website..." />;

  if (!site) return <h3>Website not found</h3>;

  return (
    <Layout>
      <BackButton />
      <EuiBreadcrumbs
        breadcrumbs={breadcrumbs}
        truncate={false}
        aria-label="An example of EuiBreadcrumbs"
      />
      <EuiSpacer size="xl" />
      <Typography variant="h4" mb={2}>
        Scheduled starting
      </Typography>


      <Typography mb={3}>
        Value (UTC):{" "}
        {site.startDate.toString() === "0001-01-01T00:00:00"
          ? "Not set"
          : site.startDate.toString()}
      </Typography>

      <Box
        component="form"
        onSubmit={handleSubmit(submitForm)}
        noValidate
        sx={{ mt: 1 }}
      >
        <Grid container alignItems="center">
          <Grid item xs={4}>
            <TextField
              id="SiteId"
              value={id}
              {...register("Id", { required: "Site id is required" })}
              sx={{ display: "none" }}
            />
            <LocalizationProvider
              dateAdapter={AdapterDayjs}
              adapterLocale="en-gb"
            >
              <Controller
                name="startDate"
                control={control}
                render={({ field }) => (
                  <DesktopDateTimePicker {...field} disablePast ampm={false} />
                )}
              />
            </LocalizationProvider>
          </Grid>
          <Grid item xs={2}>
            <LoadingButton
              type="submit"
              variant="contained"
              loading={submiting}
            >
              Save
            </LoadingButton>
          </Grid>
        </Grid>
      </Box>
    </Layout>
  );
}
