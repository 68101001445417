import {
  Box,
  Button,
  Card,
  CardContent,
  Grid,
  InputAdornment,
  Paper,
  TextField,
  Typography,
} from "@mui/material";
import Item from "@mui/material/Grid";
import { useState, useEffect, useRef } from "react";
import { Site } from "../../app/models/site";
import SiteList from "./SiteList";
import agent from "../../app/api/agent";
import LoadingComponent from "../../app/layout/LoadingComponent";
import { Link, useNavigate } from "react-router-dom";
import Layout from "../../app/layout/Layout";
import Login from "../account/Login";
import { LoginOutlined, SearchOutlined, Add } from "@mui/icons-material";
import { useAuth, useOrganization, useUser } from "@clerk/clerk-react";
import {
  EuiButton,
  EuiEmptyPrompt,
  EuiPageHeader,
  EuiSpacer,
} from "@elastic/eui";
import { useFlow } from "@frigade/react";

export default function Catalog() {
  const [sites, setSites] = useState<Site[]>([]);
  const [loading, setLoading] = useState(true);
  const [searchTerm, setSearchTerm] = useState("");
  const navigate = useNavigate();
  const { isSignedIn } = useAuth();
  const { user } = useUser();

  const { organization } = useOrganization();

  const {flow} = useFlow("flow_6fi97SYs");

  useEffect(() => {
    if (isSignedIn) {

      if (flow && user?.organizationMemberships && user.organizationMemberships.length > 0) {
         flow.steps.get('step-1')?.complete();
      }

      agent.Catalog.list()
        .then((sites) => setSites(sites))
        .catch((error) => console.log(error))
        .finally(() => setLoading(false));

    }
  }, [organization]);


  if (!isSignedIn) {
    // Redirect to login page if user is not authenticated
    navigate("/login");
    return <Login />;
  }


  if (isSignedIn && loading)
    return <LoadingComponent message="Loading websites..." />;

  return (
    <Layout>
      <EuiPageHeader
        pageTitle="Manage your HackGATE sites"
        // iconType="logoKibana"Analytics
        description="This dashboard provides an overview of your sites, allowing you to finetune your HackGATE. For instance, you can specify the days for initiating security testing, determine its duration, and select the ethical hackers who will be granted access to your project."
        rightSideItems={[
          <Button
            component={Link}
            to="/catalog/add-site"
            variant="contained"
            startIcon={<Add />}
            sx={{borderRadius: "8px"}}
          >
            Add a website
          </Button>,
        ]}
        paddingSize="none"
      />
      <EuiSpacer size="xxl" />

      {organization == null ? (
        <Button
          component={Link}
          to="/onboarding/checkUser"
          variant="contained"
          startIcon={<LoginOutlined />}
          sx={{ mb: 2 }}
        >
          Onboarding
        </Button>
      ) : null}

      {/* <Typography variant="body2" mb={4} color={grey[700]}>
          You have {sites.length} HackGATE websites to manage.
        </Typography> */}
      {sites.length === 0 ? (
        <>
          <Card variant="outlined" sx={{ mb: 2, borderRadius: "12px" }}>
            <CardContent>
              <Box
                sx={{
                  height: 400,
                  width: "100%",
                  display: "flex",
                  flexDirection: "column",
                  alignItems: "center",
                  justifyContent: "center",
                }}
              >
                <EuiEmptyPrompt
                  actions={[
                    <EuiButton color="primary" fill href="/catalog/add-site">
                      Add a website
                    </EuiButton>,
                  ]}
                  body={<p>Add a new case or change your filter settings.</p>}
                  color="subdued"
                  // iconType="logoSecurity"
                  layout="vertical"
                  title={<h2>Start adding websites</h2>}
                  titleSize="m"
                />
              </Box>
            </CardContent>
          </Card>
        </>
      ) : (
        <>
          <Typography variant="h6" mb={3}>
            Search websites
          </Typography>
          <Grid container spacing={6} alignItems="center" sx={{ mb: 3 }}>
            <Grid item xs={8}>
              <Item>
                <TextField
                  label="Search"
                  variant="outlined"
                  size="small"
                  fullWidth
                  value={searchTerm || ""}
                  onChange={(e) => setSearchTerm(e.target.value)}
                  InputProps={{
                    sx: {borderRadius: "8px"},
                    startAdornment: (
                      <InputAdornment position="start">
                        <SearchOutlined />
                      </InputAdornment>
                    ),
                  }}
                />
              </Item>
            </Grid>
            <Grid item xs={4}>
              <Item>
                {/* <EuiButton fill href="/catalog/add-site">Add a website</EuiButton> */}
              </Item>
            </Grid>
          </Grid>

          <SiteList
            sites={sites.filter((site) => site.originName.includes(searchTerm))}
          />
        </>
      )}

    {/*   <EuiSpacer size="xxl" />

      <Typography variant="body1" mb={1} mt={5}>
        Organization ID
      </Typography>
      {organization?.id} */}
    </Layout>
  );
}
